// @ts-nocheck
import { useEffect, useState } from "react";
import {
    Dropdown,
    Form,
    OverlayTrigger,
    Popover,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CatalogueService from "../../../../../../service/CatalogueService";
import { MissionStore, setFilterPayloadDeviceList, setIsNewPayloadCatelog, setPayloadSliderFalse, setPayloadSliderTrue } from "../../../../../../store/MissionSlice";
import { calculatePayloadUsage, calculate_Total_AOP_Usage, truncateNumber, uuidv4 } from "../../../../../CommonComponents/CommonFunctions";
import Dialog from "../../../../../CommonComponents/Dialog";
import { ConfigredImaging, ConfiguredComm, ConfiguredReq, PayloadsList } from "../../../Stepper.constants";
import NewPayloadCatelog from "../NewPayloadCatelog";
import InputSlider from "./InputSlider";

const SecondStep = ({ payloadCatalouge, setPayloadCatalouge, setPayloadType, setPayloadSubType, satPayloads, pyloadSrc, payloadType, setUserInput, userInput,
    payloadSubType, selectedBands, setSelectedBands, setLoading, setSelectedPayload, selectedPayload, onCheckSteps, onSelectPayload, setPayloadSliderData }) => {

    const dispatch = useDispatch()
    const filterPayloadDeviceList = useSelector((state: { mission: MissionStore }) => state.mission.filterPayloadDeviceList);
    const isNewPayloadCatelog = useSelector((state: { mission: MissionStore }) => state.mission.isNewPayloadCatelog);
    const isEditPayload = useSelector((state: { mission: MissionStore }) => state.mission.isEditPayload);
    const isPayloadSlider = useSelector((state: { mission: MissionStore }) => state.mission.isPayloadSlider);
    const EditPayloadData = useSelector((state: { mission: MissionStore }) => state.mission.EditPayloadData);


    const [showBtnGroup, setShowBtnGroup] = useState('');

    const [openInputSlider, setOpenInputSlider] = useState(false);
    const [allPayloadDevices, setAllPayloadDevices] = useState([]);
    const [previousFilterdList, setPreviousFilterdList] = useState([]);


    const [filteringInput, setFilteringInput] = useState<any>({
        'weight': {
            min: '',
            max: ''
        },
        'size': {
            min: '',
            max: ''
        },
        'gsd': {
            min: '',
            max: ''
        },
        'swath': {
            min: '',
            max: ''
        },
        'Data Rate': '',
        'Number of Beams': ''
    })

    const [selectedDeviceId, setSelectedDeviceId] = useState<string>('');
    const [deleteCatalougeId, setDeleteCatalougeId] = useState<any>('');
    const [openDeleteModel, setOpenDeleteModal] = useState<any>(false);

    const [configImg, setConfigImg] = useState<ConfigredImaging>(new ConfigredImaging());
    const [configComm, setConfigComm] = useState<ConfiguredComm>(new ConfiguredComm());


    const getAllCatalogs = () => {
        const response = CatalogueService.getCatalogue("Payload Server", "Payload");
        response?.then((data) => {
            if (data.data) {
                let filter_device = data.data.filter(device => payloadCatalouge === device?.["component_specific_attributes"]?.["payloadType"])
                reset_filtering_inputs()

                setAllPayloadDevices(filter_device);
                setPayloadType('')
                setPayloadSubType('')
                setSelectedBands([])
                setConfigComm(new ConfiguredComm())
                setConfigImg(new ConfigredImaging())
                dispatch(setFilterPayloadDeviceList(filter_device));
                setPreviousFilterdList(filter_device)
                if (isNewPayloadCatelog) {
                    dispatch(setIsNewPayloadCatelog(false))
                }
                setLoading(false)
            }
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        if (payloadCatalouge !== '' && !isNewPayloadCatelog) {
            getAllCatalogs()
        }
    }, [payloadCatalouge])

    const reset_filtering_inputs = () => {
        setFilteringInput({
            'weight': {
                min: '',
                max: ''
            },
            'size': {
                min: '',
                max: ''
            },
            'gsd': {
                min: '',
                max: ''
            },
            'swath': {
                min: '',
                max: ''
            },
            'Data Rate': '',
            'Number of Beams': ''
        })
    }


    useEffect(() => {
        if (isEditPayload) {
            setPayloadCatalouge(payloadCatalouge === '' ? EditPayloadData?.["atmos-data"]?.["component_specific_attributes"]?.["payloadType"] : payloadCatalouge);
            setSelectedDeviceId(EditPayloadData?.["atmos-data"]?.["uniqueId"]);
            setOpenInputSlider(payloadCatalouge === EditPayloadData?.["atmos-data"]?.["component_specific_attributes"]?.["payloadType"] ? true : false);

            if (EditPayloadData?.["atmos-data"]?.["component_specific_attributes"]?.["payloadType"] === "Earth Observation") {
                setConfigImg(EditPayloadData?.["user_data"]?.["typeSpecificParams"] !== undefined ? EditPayloadData?.["user_data"]?.["typeSpecificParams"] : {});
            } else if (EditPayloadData?.["atmos-data"]?.["component_specific_attributes"]?.["payloadType"] === "Communication") {
                setConfigComm(EditPayloadData?.["user_data"]?.["typeSpecificParams"]);
            }
            let payload = {
                'atmos-data': EditPayloadData?.["atmos-data"],
                numUnits: EditPayloadData?.["user_data"]?.['numUnits'],
                downlinkDatasize: EditPayloadData?.["user_data"]?.['downlinkDatasize'],
                time_per_orbit: EditPayloadData?.["user_data"]?.['time_per_orbit'],
                AltMeasureAccuracy: EditPayloadData?.["user_data"]?.['AltMeasureAccuracy'],
                interfaces: EditPayloadData?.['user_data']?.['selected_interfaces'],
                gpioCount: EditPayloadData?.['user_data']?.['gpio_count'],
                payloadImageSrc: EditPayloadData?.['atmos-data']?.['src'],
                payloadName: EditPayloadData?.['user_data']?.['payload_name'],
            }
            setUserInput(payload)
            onSelectDevices(payload)
            setLoading(false)
        }
    }, [isEditPayload, payloadCatalouge]);


    const deleteUserCatalouge = (catalougeId) => {
        setLoading(true)
        CatalogueService.deleteCatalogue(catalougeId)?.then((response) => {
            let remainingList = filterPayloadDeviceList?.filter(payload => payload?.['uniqueId'] !== catalougeId)
            if (selectedPayload?.['atmos-data']?.['uniqueId'] === catalougeId) {
                setSelectedPayload({})
                setPayloadSliderData({})
            }
            dispatch(setPayloadSliderFalse())
            dispatch(setFilterPayloadDeviceList(remainingList))
            getAllCatalogs()
            toast.success('Payload Device deleted successfully', { toastId: 'o' })
        }).catch((err) => {
            console.log('Something went wrong');
            setLoading(false)
        })
    }


    const onSelectDevices = (data) => {

        let default_sequence = []
        let userConfig = new ConfiguredReq();
        let typeConfig: any = {};
        userConfig = {
            numUnits: 0,
            downlinkDatasize: data?.['downlinkDatasize'] ? +data?.['downlinkDatasize'] : 1,
            time_per_orbit: data?.['time_per_orbit'] ? +data?.['time_per_orbit'] : 5,
            AltMeasureAccuracy: data?.['AltMeasureAccuracy'] ? +data?.['AltMeasureAccuracy'] : 1,
        };
        if (data?.['atmos-data']?.["component_specific_attributes"]?.["payloadType"] === "Communication") {
            typeConfig = configComm;
        }
        let time_per_orbit = data?.['time_per_orbit'] ? data?.['time_per_orbit'] : '5'
        let dutyCycle: number = truncateNumber(Number(time_per_orbit / 90), 2); // ------> dutycycle = timeperOrbit/90
        let aop: number = truncateNumber(Number(data?.['atmos-data']?.["max_power_consumption_in_watts"]) * dutyCycle, 2);  // -----> aop = maxPower * dutyCycle
        let peakSolarPower: number = truncateNumber((aop / 0.8 * 2 * 2), 2); //----->PeakSolarPower = aop * efficiency(80%) * eclipse Offset(2) * 2

        const atmosData = ({
            ...data?.['atmos-data'],
            src: data?.['payloadImageSrc']
        })
        if (atmosData.sequences && atmosData.sequences.length > 0) {
            let atmos_sequence = atmosData.sequences.map(seq => {
                return {
                    seqName: seq?.['seqName'],
                    seqPowerRequirements: seq?.['seqPowerRequirements'],
                    stagedTCId: Number(seq?.['stagedTCId']),
                    seqId: uuidv4(),
                }
            })
            default_sequence = atmos_sequence
        }
        let newPayload = {
            "atmos-data": atmosData,
            "user_data": {
                ...userConfig,
                id: (isEditPayload && atmosData?.['uniqueId'] === EditPayloadData?.['atmos-data']?.['uniqueId']) ? EditPayloadData?.['user_data']?.['id'] : uuidv4(),
                payload_name: (data?.['payloadName'] === '' || data?.['payloadName'] === undefined) ? data?.['atmos-data']?.['name'] : data?.['payloadName'],
                aop: aop,
                typeSpecificParams: {},
                selected_interfaces: data?.['interfaces'],
                dutyCycle: truncateNumber(Number(dutyCycle * 100), 1),
                peak_solar_power: peakSolarPower,
                gpio_count: Number(data?.['gpioCount']),
            },
            sequences: (isEditPayload && atmosData?.['uniqueId'] === EditPayloadData?.['atmos-data']?.['uniqueId'] && EditPayloadData?.['sequences']) ? EditPayloadData?.['sequences'] : default_sequence,
            metrics: data?.['atmos-data']?.['metrics']
        }

        if (satPayloads?.length > 0 && !isEditPayload) {
            calculatePayloadUsage([...satPayloads, newPayload], dispatch)
            calculate_Total_AOP_Usage([...satPayloads, newPayload], [], dispatch)
        } else if (satPayloads?.length > 0 && isEditPayload) {
            let filterPayloads = satPayloads?.filter(
                (payload) => payload?.user_data?.id !== EditPayloadData?.['user_data']?.['id']
            )

            calculatePayloadUsage([...filterPayloads, newPayload], dispatch)
            calculate_Total_AOP_Usage([...filterPayloads, newPayload], [], dispatch)
        } else {
            calculatePayloadUsage([newPayload], dispatch)
            calculate_Total_AOP_Usage([newPayload], [], dispatch)
        }
        setSelectedPayload(newPayload);
        setPayloadSliderData(newPayload);
    };

    return (
        <div className='d-flex w-100 h-90' onClick={() => setShowBtnGroup("")}>
            {!isNewPayloadCatelog &&
                <div className="d-flex h-90 w-100">
                    <div className='d-flex w-18 py-4 rounded-3'>
                        <div className='w-100 h-100 overflow-y-auto px-5 d-flex flex-column gap-3'>
                            {payloadCatalouge === "Earth Observation" &&
                                <div className="d-flex w-100 flex-column gap-3">
                                    <div className="form-row justify-content-between group-radio">
                                        <div className="d-flex flex-column">
                                            <h3 className="mb-3 color-text color-atmos-secondary">{payloadCatalouge}</h3>
                                            <label className="color-text mb-3">Payload Type</label>
                                            <div className="w-100 d-flex gap-2 flex-wrap">
                                                {["Imaging", "Radar", "AIS"].map(val => (
                                                    <button key={val} className={`btn btn-payload py-3 px-5 position-relative ${payloadType === val ? 'btn-primary' : 'btn-outline-primary'}`}
                                                        onClick={() => {
                                                            if (payloadType === val) {
                                                                setPayloadType('')
                                                                dispatch(setFilterPayloadDeviceList(allPayloadDevices))
                                                                setPreviousFilterdList(allPayloadDevices)
                                                                reset_filtering_inputs()
                                                            } else {
                                                                setPayloadType(val)
                                                                setPayloadSubType('')
                                                                let filter_device = allPayloadDevices?.filter(device => device?.["component_specific_attributes"]?.["payloadSubType"] === val)
                                                                dispatch(setFilterPayloadDeviceList(filter_device))
                                                                if (filter_device.length > 0) {
                                                                    setPreviousFilterdList(filter_device)
                                                                    reset_filtering_inputs()
                                                                }
                                                            }
                                                            setConfigImg(new ConfigredImaging())
                                                        }}>{val.split(" ")[0]}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    {payloadType === "Imaging" && (
                                        <div className="form-row justify-content-between group-radio">
                                            <div className="d-flex flex-column">
                                                <label className="color-text mb-3">Payload SubType</label>
                                                <div className="w-100 d-flex gap-2 flex-wrap">
                                                    {["MX / Multispectral", "Thermal IR", "SWIR", "Hyperspectral"].map(val => (
                                                        <button key={val} className={`btn btn-payload py-3 px-4 position-relative ${payloadSubType === val ? 'btn-primary' : 'btn-outline-primary'}`}
                                                            onClick={() => {
                                                                if (payloadSubType === val) {
                                                                    setPayloadSubType('')
                                                                    let filter_device = allPayloadDevices?.filter(device => device?.["component_specific_attributes"]?.["payloadSubType"] === payloadType)
                                                                    dispatch(setFilterPayloadDeviceList(filter_device))
                                                                    if (filter_device.length > 0) {
                                                                        setPreviousFilterdList(filter_device)
                                                                        reset_filtering_inputs()
                                                                    }
                                                                } else {
                                                                    setPayloadSubType(val)
                                                                    let filter_device = allPayloadDevices?.filter(device => device?.["component_specific_attributes"]?.["payloadDetails"] === val)
                                                                    dispatch(setFilterPayloadDeviceList(filter_device))
                                                                    if (filter_device.length > 0) {
                                                                        setPreviousFilterdList(filter_device)
                                                                        reset_filtering_inputs()
                                                                    }
                                                                }
                                                                setConfigImg(new ConfigredImaging())
                                                            }}>{val === "MX / Multispectral" ? "Multispectral" : val.split(" ")[0]}
                                                        </button>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {payloadType === "Radar" && (
                                        <div className="form-row justify-content-between group-radio">
                                            <div className="d-flex flex-column">
                                                <label className="color-text mb-3">Payload SubType</label>
                                                <div className="w-100 d-flex gap-2 flex-wrap">
                                                    {["Microwave", "SAR", "LIDAR"].map(val => (
                                                        <button key={val} className={`btn btn-payload py-3 px-4 position-relative ${payloadSubType === val ? 'btn-primary' : 'btn-outline-primary'}`}
                                                            onClick={() => {
                                                                if (payloadSubType === val) {
                                                                    setPayloadSubType('')
                                                                    let filter_device = allPayloadDevices?.filter(device => device?.["component_specific_attributes"]?.["payloadSubType"] === payloadType)
                                                                    dispatch(setFilterPayloadDeviceList(filter_device))
                                                                    if (filter_device.length > 0) {
                                                                        setPreviousFilterdList(filter_device)
                                                                        reset_filtering_inputs()
                                                                    }
                                                                } else {
                                                                    setPayloadSubType(val)
                                                                    let filter_device = allPayloadDevices?.filter(device => device?.["component_specific_attributes"]?.["payloadDetails"] === val)
                                                                    dispatch(setFilterPayloadDeviceList(filter_device))
                                                                    if (filter_device.length > 0) {
                                                                        setPreviousFilterdList(filter_device)
                                                                        reset_filtering_inputs()
                                                                    }
                                                                }
                                                                setConfigImg(new ConfigredImaging())
                                                            }}>{val.split(" ")[0]}
                                                        </button>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}


                                    {(payloadType === "Imaging" || payloadType === "") && <div className="d-flex flex-column w-100">
                                        <Form.Label className="color-text mb-2">Ground Sampling Distance (m)</Form.Label>
                                        <div className="w-100 d-flex align-items-center justify-content-between gap-1">
                                            <div className="w-50 d-flex flex-column gap-2">
                                                <span className="fs-13 color-text">Minimum</span>
                                                <Form.Control
                                                    required={
                                                        payloadType === "Imaging" &&
                                                        payloadSubType === "MX / Multispectral"
                                                    }
                                                    type='text'
                                                    placeholder="Minimum GSD "
                                                    value={filteringInput?.['gsd']?.['min']}
                                                    onKeyDown={(e) => {
                                                        if (e.keyCode === 13) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(event) => {
                                                        event.preventDefault();
                                                        if (!isNaN(event.target.value) && event.target.value >= 0) {
                                                            setFilteringInput({
                                                                ...filteringInput,
                                                                gsd: {
                                                                    min: event.target.value,
                                                                    max: +event.target.value <= +filteringInput?.['gsd']?.['max'] ? filteringInput?.['gsd']?.['max'] : '',
                                                                }
                                                            })
                                                            let filter_device = event.target.value !== '' ? previousFilterdList?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['GSD'] >= +event.target.value) : previousFilterdList
                                                            filter_device = (filteringInput?.['gsd']?.['max'] !== '' && +filteringInput?.['gsd']?.['max'] >= +event.target.value) ? filter_device?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['GSD'] <= +filteringInput?.['gsd']?.['max']) : filter_device
                                                            filter_device = filteringInput?.['swath']?.['min'] !== '' ? filter_device.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Swath'] >= +filteringInput?.['swath']?.['min']) : filter_device
                                                            filter_device = (filteringInput?.['swath']?.['max'] !== '' && filteringInput?.['swath']?.['max'] >= +filteringInput?.['swath']?.['min']) ? filter_device.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Swath'] <= +filteringInput?.['swath']?.['max']) : filter_device
                                                            filter_device = filteringInput?.['weight']?.['min'] !== '' ? filter_device.filter(device => (+device?.["weight_in_gms"] / 1000) >= +filteringInput?.['weight']?.['min']) : filter_device
                                                            filter_device = (filteringInput?.['weight']?.['max'] !== '' && +filteringInput?.['weight']?.['max'] >= +filteringInput?.['weight']?.['min']) ? filter_device.filter(device => (+device?.["weight_in_gms"] / 1000) <= +filteringInput?.['weight']?.['max']) : filter_device
                                                            filter_device = filteringInput?.['size']?.['min'] !== '' ? filter_device.filter(device => +device?.["product-cookie"]?.['Size'] >= +filteringInput?.['size']?.['min']) : filter_device
                                                            filter_device = (filteringInput?.['size']?.['max'] !== '' && +filteringInput?.['size']?.['max'] >= +filteringInput?.['size']?.['min']) ? filter_device.filter(device => +device?.["product-cookie"]?.['Size'] <= +filteringInput?.['size']?.['max']) : filter_device
                                                            dispatch(setFilterPayloadDeviceList(filter_device))
                                                        }
                                                    }}
                                                ></Form.Control>
                                            </div>
                                            <i className="fe fe-minus mt-5 color_history"></i>
                                            <div className="w-50 d-flex flex-column gap-2">
                                                <span className="fs-13">Maximum</span>
                                                <Form.Control
                                                    required={
                                                        payloadType === "Imaging" &&
                                                        payloadSubType === "MX / Multispectral"
                                                    }
                                                    type='text'
                                                    placeholder="Maximum GSD"
                                                    value={filteringInput?.['gsd']?.['max']}
                                                    onKeyDown={(e) => {
                                                        if (e.keyCode === 13) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(event) => {
                                                        event.preventDefault();
                                                        if (!isNaN(event.target.value) && event.target.value >= 0) {
                                                            setFilteringInput({
                                                                ...filteringInput,
                                                                gsd: {
                                                                    min: filteringInput?.['gsd']?.['min'],
                                                                    max: event.target.value,
                                                                }
                                                            })
                                                            let filter_device = event.target.value !== '' ? +event.target.value >= +filteringInput?.['gsd']?.['min'] ? previousFilterdList?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['GSD'] <= +event.target.value) : previousFilterdList?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['GSD'] >= +filteringInput?.['gsd']?.['min']) : previousFilterdList
                                                            filter_device = (filteringInput?.['gsd']?.['min'] !== '' && +filteringInput?.['gsd']?.['min'] <= +event.target.value) ? filter_device?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['GSD'] >= +filteringInput?.['gsd']?.['min']) : filter_device
                                                            filter_device = filteringInput?.['swath']?.['min'] !== '' ? filter_device.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Swath'] >= +filteringInput?.['swath']?.['min']) : filter_device
                                                            filter_device = (filteringInput?.['swath']?.['max'] !== '' && +filteringInput?.['swath']?.['max'] >= +filteringInput?.['swath']?.['min']) ? filter_device.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Swath'] <= +filteringInput?.['swath']?.['max']) : filter_device
                                                            filter_device = filteringInput?.['weight']?.['min'] !== '' ? filter_device.filter(device => (+device?.["weight_in_gms"] / 1000) >= +filteringInput?.['weight']?.['min']) : filter_device
                                                            filter_device = (filteringInput?.['weight']?.['max'] !== '' && +filteringInput?.['weight']?.['max'] >= +filteringInput?.['weight']?.['min']) ? filter_device.filter(device => (+device?.["weight_in_gms"] / 1000) <= +filteringInput?.['weight']?.['max']) : filter_device
                                                            filter_device = filteringInput?.['size']?.['min'] !== '' ? filter_device.filter(device => +device?.["product-cookie"]?.['Size'] >= +filteringInput?.['size']?.['min']) : filter_device
                                                            filter_device = (filteringInput?.['size']?.['max'] !== '' && +filteringInput?.['size']?.['max'] >= +filteringInput?.['size']?.['min']) ? filter_device.filter(device => +device?.["product-cookie"]?.['Size'] <= +filteringInput?.['size']?.['max']) : filter_device
                                                            dispatch(setFilterPayloadDeviceList(filter_device))
                                                        }
                                                    }}
                                                ></Form.Control>
                                            </div>
                                        </div>
                                    </div>}


                                    {(payloadType === "Imaging" || payloadType === "") && <div className="d-flex flex-column w-100">
                                        <Form.Label className="color-text mb-2">Swath (km)</Form.Label>
                                        <div className="w-100 d-flex align-items-center justify-content-between gap-1">
                                            <div className="w-50 d-flex flex-column gap-2">
                                                <span className="fs-13 color-text">Minimum</span>
                                                <Form.Control
                                                    required={
                                                        payloadType === "Imaging" &&
                                                        payloadSubType === "MX / Multispectral"
                                                    }
                                                    type="text"
                                                    placeholder="Minimum swath"
                                                    value={filteringInput?.['swath']?.['min']}
                                                    onKeyDown={(e) => {
                                                        if (e.keyCode === 13) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(event) => {
                                                        event.preventDefault();
                                                        if (!isNaN(event.target.value) && event.target.value >= 0) {
                                                            setFilteringInput({
                                                                ...filteringInput,
                                                                swath: {
                                                                    min: event.target.value,
                                                                    max: +event.target.value <= +filteringInput?.['swath']?.['max'] ? filteringInput?.['swath']?.['max'] : '',
                                                                }
                                                            })
                                                            let filter_device = event.target.value !== '' ? previousFilterdList?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Swath'] >= +event.target.value) : previousFilterdList
                                                            filter_device = (filteringInput?.['swath']?.['max'] !== '' && +filteringInput?.['swath']?.['max'] >= +event.target.value) ? filter_device?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Swath'] <= +filteringInput?.['swath']?.['max']) : filter_device
                                                            filter_device = filteringInput?.['gsd']?.['min'] !== '' ? filter_device?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['GSD'] >= +filteringInput?.['gsd']?.['min']) : filter_device
                                                            filter_device = (filteringInput?.['gsd']?.['max'] !== '' && +filteringInput?.['gsd']?.['max'] >= +filteringInput?.['gsd']?.['min']) ? filter_device?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['GSD'] <= +filteringInput?.['gsd']?.['max']) : filter_device
                                                            filter_device = filteringInput?.['weight']?.['min'] !== '' ? filter_device.filter(device => (+device?.["weight_in_gms"] / 1000) >= +filteringInput?.['weight']?.['min']) : filter_device
                                                            filter_device = (filteringInput?.['weight']?.['max'] !== '' && +filteringInput?.['weight']?.['max'] >= +filteringInput?.['weight']?.['min']) ? filter_device.filter(device => (+device?.["weight_in_gms"] / 1000) <= +filteringInput?.['weight']?.['max']) : filter_device
                                                            filter_device = filteringInput?.['size']?.['min'] !== '' ? filter_device.filter(device => +device?.["product-cookie"]?.['Size'] >= +filteringInput?.['size']?.['min']) : filter_device
                                                            filter_device = (filteringInput?.['size']?.['max'] !== '' && +filteringInput?.['size']?.['max'] >= +filteringInput?.['size']?.['min']) ? filter_device.filter(device => +device?.["product-cookie"]?.['Size'] <= +filteringInput?.['size']?.['max']) : filter_device
                                                            dispatch(setFilterPayloadDeviceList(filter_device))
                                                        }
                                                    }}
                                                ></Form.Control>
                                            </div>
                                            <i className="fe fe-minus mt-5 color_history"></i>
                                            <div className="w-50 d-flex flex-column gap-2">
                                                <span className="fs-13 color-text">Maximum</span>
                                                <Form.Control
                                                    required={
                                                        payloadType === "Imaging" &&
                                                        payloadSubType === "MX / Multispectral"
                                                    }
                                                    type="text"
                                                    placeholder="Maximum swath"
                                                    value={filteringInput?.['swath']?.['max']}
                                                    onKeyDown={(e) => {
                                                        if (e.keyCode === 13) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(event) => {
                                                        event.preventDefault();
                                                        if (!isNaN(event.target.value) && event.target.value >= 0) {
                                                            setFilteringInput({
                                                                ...filteringInput,
                                                                swath: {
                                                                    min: filteringInput?.['swath']?.['min'],
                                                                    max: event.target.value,
                                                                }
                                                            })
                                                            let filter_device = event.target.value !== '' ? +event.target.value > +filteringInput?.['swath']?.['min'] ? previousFilterdList?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Swath'] <= +event.target.value) : previousFilterdList?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Swath'] >= +filteringInput?.['swath']?.['min']) : previousFilterdList
                                                            filter_device = (filteringInput?.['swath']?.['min'] !== '' && +filteringInput?.['swath']?.['min'] <= +event.target.value) ? filter_device?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Swath'] >= +filteringInput?.['swath']?.['min']) : filter_device
                                                            filter_device = filteringInput?.['gsd']?.['min'] !== '' ? filter_device?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['GSD'] >= +filteringInput?.['gsd']?.['min']) : filter_device
                                                            filter_device = (filteringInput?.['gsd']?.['max'] !== '' && +filteringInput?.['gsd']?.['max'] >= +filteringInput?.['gsd']?.['min']) ? filter_device?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['GSD'] <= +filteringInput?.['gsd']?.['max']) : filter_device
                                                            filter_device = filteringInput?.['weight']?.['min'] !== '' ? filter_device.filter(device => (+device?.["weight_in_gms"] / 1000) >= +filteringInput?.['weight']?.['min']) : filter_device
                                                            filter_device = (filteringInput?.['weight']?.['max'] !== '' && +filteringInput?.['weight']?.['max'] >= +filteringInput?.['weight']?.['min']) ? filter_device.filter(device => (+device?.["weight_in_gms"] / 1000) <= +filteringInput?.['weight']?.['max']) : filter_device
                                                            filter_device = filteringInput?.['size']?.['min'] !== '' ? filter_device.filter(device => +device?.["product-cookie"]?.['Size'] >= +filteringInput?.['size']?.['min']) : filter_device
                                                            filter_device = (filteringInput?.['size']?.['max'] !== '' && +filteringInput?.['size']?.['max'] >= +filteringInput?.['size']?.['min']) ? filter_device.filter(device => +device?.["product-cookie"]?.['Size'] <= +filteringInput?.['size']?.['max']) : filter_device
                                                            dispatch(setFilterPayloadDeviceList(filter_device))
                                                        }
                                                    }}
                                                ></Form.Control>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            }

                            {payloadCatalouge === "Communication" &&
                                <div className="w-100 d-flex flex-column gap-3">
                                    <div className="form-row group-radio d-flex flex-column">
                                        <h3 className="mb-3 color-text">{payloadCatalouge}</h3>
                                        <label className="color-text mb-3">Payload Type</label>
                                        <div className="w-100 d-flex gap-3 flex-wrap">
                                            {["RF", "Optical"].map(val => (
                                                <button key={val} className={`btn btn-payload py-3 position-relative ${payloadType === val ? 'btn-primary' : 'btn-outline-primary'}`}
                                                    onClick={() => {
                                                        if (payloadType === val) {
                                                            setPayloadType('')
                                                            dispatch(setFilterPayloadDeviceList(allPayloadDevices))
                                                            setSelectedBands([])
                                                            setPreviousFilterdList(allPayloadDevices)
                                                            reset_filtering_inputs()
                                                        } else {
                                                            setPayloadType(val)
                                                            setPayloadSubType('')
                                                            let filter_device = allPayloadDevices?.filter(device => device?.["component_specific_attributes"]?.["payloadSubType"] === val)
                                                            dispatch(setFilterPayloadDeviceList(filter_device))
                                                            if (filter_device.length > 0) {
                                                                setPreviousFilterdList(filter_device)
                                                                reset_filtering_inputs()
                                                            }
                                                        }
                                                        setSelectedBands([])
                                                        setConfigComm(new ConfiguredComm())
                                                    }}>{val}
                                                </button>
                                            ))}
                                        </div>
                                    </div>

                                    {payloadType === "RF" && (
                                        <div className="form-row w-100 d-flex flex-column ">
                                            <Form.Label className="color-text">Frequency Bands</Form.Label>
                                            <div className="w-100 d-flex flex-wrap gap-3 mt-2">
                                                {["S Band", "V Band", "E Band", "Ka Band", "Ku Band"].map(btn => (
                                                    <button key={btn} className={`btn btn-payload position-relative ${selectedBands.includes(btn) ? 'btn-primary' : 'btn-outline-primary'}`}
                                                        onClick={() => {
                                                            if (selectedBands.includes(btn)) {
                                                                let selected_bands = selectedBands.filter(band => band !== btn)
                                                                setSelectedBands(selected_bands)

                                                                let filter_device = []
                                                                if (selected_bands.length > 0) {
                                                                    allPayloadDevices?.filter(device => {
                                                                        if (device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Frequency Bands'] && selected_bands.length > 0) {
                                                                            selected_bands.map(band => {
                                                                                if (device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Frequency Bands'].includes(band)) {
                                                                                    if (!filter_device.includes(device)) {
                                                                                        filter_device.push(device)
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    })
                                                                    dispatch(setFilterPayloadDeviceList(filter_device))
                                                                    if (filter_device.length > 0) {
                                                                        setPreviousFilterdList(filter_device)
                                                                        reset_filtering_inputs()
                                                                    }
                                                                } else {
                                                                    let filter_device = allPayloadDevices?.filter(device => device?.["component_specific_attributes"]?.["payloadSubType"] === payloadType)
                                                                    dispatch(setFilterPayloadDeviceList(filter_device))
                                                                    if (filter_device.length > 0) {
                                                                        setPreviousFilterdList(filter_device)
                                                                        reset_filtering_inputs()
                                                                    }
                                                                }
                                                            } else {
                                                                let selected_bands = [...selectedBands, btn]
                                                                setSelectedBands(selected_bands)

                                                                let filter_device: any[] = []

                                                                allPayloadDevices?.map(device => {
                                                                    if (device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Frequency Bands'] && selected_bands.length > 0) {
                                                                        selected_bands.map(band => {
                                                                            if (device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Frequency Bands'].includes(band)) {
                                                                                if (!filter_device.includes(device)) {
                                                                                    filter_device.push(device)
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                })
                                                                dispatch(setFilterPayloadDeviceList(filter_device))
                                                                if (filter_device.length > 0) {
                                                                    setPreviousFilterdList(filter_device)
                                                                    reset_filtering_inputs()
                                                                }
                                                            }
                                                            setConfigComm(new ConfiguredComm())
                                                        }}
                                                    >{btn}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    {(payloadType === "RF" || payloadType === "") && <div className="d-flex flex-column w-100">
                                        <Form.Label className="color-text mb-3">Data Rate (Mbps)</Form.Label>
                                        <Form.Control
                                            required={payloadType === "RF"}
                                            type="text"
                                            placeholder="Enter data rate (Mbps)"
                                            value={filteringInput?.['Data Rate']}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onChange={(event) => {
                                                event.preventDefault();
                                                if (!isNaN(event.target.value) && event.target.value >= 0) {
                                                    setFilteringInput({
                                                        ...filteringInput,
                                                        'Data Rate': event.target.value,
                                                    })
                                                    let filter_device = previousFilterdList?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Data Rate'] >= +event.target.value)
                                                    filter_device = filteringInput?.['Number of Beams'] !== '' ? filter_device?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Number of Beams'] >= +filteringInput?.['Number of Beams']) : filter_device
                                                    filter_device = filteringInput?.['weight']?.['min'] !== '' ? filter_device.filter(device => (+device?.["weight_in_gms"] / 1000) >= +filteringInput?.['weight']?.['min']) : filter_device
                                                    filter_device = (filteringInput?.['weight']?.['max'] !== '' && +filteringInput?.['weight']?.['max'] >= +filteringInput?.['weight']?.['min']) ? filter_device.filter(device => (+device?.["weight_in_gms"] / 1000) <= +filteringInput?.['weight']?.['max']) : filter_device
                                                    filter_device = filteringInput?.['size']?.['min'] !== '' ? filter_device.filter(device => +device?.["product-cookie"]?.['Size'] >= +filteringInput?.['size']?.['min']) : filter_device
                                                    filter_device = (filteringInput?.['size']?.['max'] !== '' && +filteringInput?.['size']?.['max'] >= +filteringInput?.['size']?.['min']) ? filter_device.filter(device => +device?.["product-cookie"]?.['Size'] <= +filteringInput?.['size']?.['max']) : filter_device
                                                    dispatch(setFilterPayloadDeviceList(filter_device))
                                                }
                                            }}
                                        ></Form.Control>
                                    </div>}
                                    {(payloadType === "RF" || payloadType === "") && <div className="d-flex flex-column w-100">
                                        <Form.Label className="color-text mb-3">Number of Beams</Form.Label>
                                        <Form.Control
                                            required={payloadType === "RF"}
                                            type="text"
                                            placeholder="Enter number of beams"
                                            value={filteringInput?.['Number of Beams']}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onChange={(event) => {
                                                event.preventDefault();
                                                if (!isNaN(event.target.value) && event.target.value >= 0) {
                                                    setFilteringInput({
                                                        ...filteringInput,
                                                        'Number of Beams': event.target.value,
                                                    })
                                                    let filter_device = previousFilterdList?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Number of Beams'] >= +event.target.value)
                                                    filter_device = filteringInput?.['Data Rate'] !== '' ? filter_device?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Data Rate'] >= +filteringInput?.['Data Rate']) : filter_device
                                                    filter_device = filteringInput?.['weight']?.['min'] !== '' ? filter_device.filter(device => (+device?.["weight_in_gms"] / 1000) >= +filteringInput?.['weight']?.['min']) : filter_device
                                                    filter_device = (filteringInput?.['weight']?.['max'] !== '' && +filteringInput?.['weight']?.['max'] >= +filteringInput?.['weight']?.['min']) ? filter_device.filter(device => (+device?.["weight_in_gms"] / 1000) <= +filteringInput?.['weight']?.['max']) : filter_device
                                                    filter_device = filteringInput?.['size']?.['min'] !== '' ? filter_device.filter(device => +device?.["product-cookie"]?.['Size'] >= +filteringInput?.['size']?.['min']) : filter_device
                                                    filter_device = (filteringInput?.['size']?.['max'] !== '' && +filteringInput?.['size']?.['max'] >= +filteringInput?.['size']?.['min']) ? filter_device.filter(device => +device?.["product-cookie"]?.['Size'] <= +filteringInput?.['size']?.['max']) : filter_device
                                                    dispatch(setFilterPayloadDeviceList(filter_device))
                                                }
                                            }}
                                        ></Form.Control>
                                    </div>}
                                </div>
                            }


                            <div className="d-flex flex-column w-100">
                                <Form.Label className="color-text mb-2">Mass (kg)</Form.Label>
                                <div className="d-flex align-items-center justify-content-between gap-1 w-100">
                                    <div className="w-50 d-flex flex-column gap-2">
                                        <span className="fs-13 color-text">Minimum</span>
                                        <Form.Control
                                            value={filteringInput?.weight?.min}
                                            type="text"
                                            placeholder="Minimum mass"
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onChange={(event) => {
                                                event.preventDefault();
                                                if (!isNaN(event.target.value) && event.target.value >= 0) {
                                                    setFilteringInput({
                                                        ...filteringInput,
                                                        weight: {
                                                            min: event.target.value,
                                                            max: +event.target.value <= +filteringInput?.weight?.max ? filteringInput?.weight?.max : ''
                                                        },
                                                    })
                                                    let filter_device: any = []
                                                    filter_device = previousFilterdList?.filter(device => (+device?.["weight_in_gms"] / 1000) >= +event.target.value)
                                                    filter_device = (filteringInput?.['weight']?.['max'] !== '' && +filteringInput?.['weight']?.['max'] >= +event.target.value) ? filter_device.filter(device => (+device?.["weight_in_gms"] / 1000) <= +filteringInput?.['weight']?.['max']) : filter_device
                                                    if (payloadCatalouge === 'Earth Observation') {
                                                        filter_device = filteringInput?.['swath']?.['min'] !== '' ? filter_device.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Swath'] >= +filteringInput?.['swath']?.['min']) : filter_device
                                                        filter_device = (filteringInput?.['swath']?.['max'] !== '' && +filteringInput?.['swath']?.['max'] >= +filteringInput?.['swath']?.['min']) ? filter_device.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Swath'] <= +filteringInput?.['swath']?.['max']) : filter_device
                                                        filter_device = filteringInput?.['gsd']?.['min'] !== '' ? filter_device?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['GSD'] >= +filteringInput?.['gsd']?.['min']) : filter_device
                                                        filter_device = (filteringInput?.['gsd']?.['max'] !== '' && +filteringInput?.['gsd']?.['max'] >= +filteringInput?.['gsd']?.['min']) ? filter_device?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['GSD'] <= +filteringInput?.['gsd']?.['max']) : filter_device
                                                    } else if (payloadCatalouge === 'Communication') {
                                                        filter_device = filteringInput?.['Data Rate'] !== '' ? filter_device?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Data Rate'] >= +filteringInput?.['Data Rate']) : filter_device
                                                        filter_device = filteringInput?.['Number of Beams'] !== '' ? filter_device?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Number of Beams'] >= +filteringInput?.['Number of Beams']) : filter_device
                                                    }
                                                    filter_device = filteringInput?.['size']?.['min'] !== '' ? filter_device.filter(device => +device?.["product-cookie"]?.['Size'] >= +filteringInput?.['size']?.['min']) : filter_device
                                                    filter_device = (filteringInput?.['size']?.['max'] !== '' && +filteringInput?.['size']?.['max'] >= +filteringInput?.['size']?.['min']) ? filter_device.filter(device => +device?.["product-cookie"]?.['Size'] <= +filteringInput?.['size']?.['max']) : filter_device
                                                    dispatch(setFilterPayloadDeviceList(filter_device))
                                                }
                                            }}
                                        ></Form.Control>
                                    </div>
                                    <i className="fe fe-minus mt-6 color_history"></i>
                                    <div className="w-50 d-flex flex-column gap-2">
                                        <span className="fs-13 color-text">Maximum</span>
                                        <Form.Control
                                            value={filteringInput?.weight?.max}
                                            type="text"
                                            placeholder="Maximum mass"
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onChange={(event) => {
                                                event.preventDefault();
                                                if (!isNaN(event.target.value) && event.target.value >= 0) {
                                                    setFilteringInput({
                                                        ...filteringInput,
                                                        weight: {
                                                            ...filteringInput?.weight,
                                                            max: event.target.value
                                                        },
                                                    })
                                                    let filter_device: any = []
                                                    filter_device = event.target.value !== '' && +event.target.value >= +filteringInput?.['weight']?.['min'] ? previousFilterdList?.filter(device => (+device?.["weight_in_gms"] / 1000) <= +event.target.value) : previousFilterdList?.filter(device => (+device?.["weight_in_gms"] / 1000) >= +filteringInput?.['weight']?.['min'])
                                                    filter_device = (filteringInput?.['weight']?.['min'] !== '' && +filteringInput?.['weight']?.['min'] <= +event.target.value) ? filter_device.filter(device => (+device?.["weight_in_gms"] / 1000) >= +filteringInput?.['weight']?.['min']) : filter_device
                                                    if (payloadCatalouge === 'Earth Observation') {
                                                        filter_device = filteringInput?.['swath']?.['min'] !== '' ? filter_device.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Swath'] >= +filteringInput?.['swath']?.['min']) : filter_device
                                                        filter_device = (filteringInput?.['swath']?.['max'] !== '' && +filteringInput?.['swath']?.['max'] >= +filteringInput?.['swath']?.['min']) ? filter_device.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Swath'] <= +filteringInput?.['swath']?.['max']) : filter_device
                                                        filter_device = filteringInput?.['gsd']?.['min'] !== '' ? filter_device?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['GSD'] >= +filteringInput?.['gsd']?.['min']) : filter_device
                                                        filter_device = (filteringInput?.['gsd']?.['max'] !== '' && +filteringInput?.['gsd']?.['max'] >= +filteringInput?.['gsd']?.['min']) ? filter_device?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['GSD'] <= +filteringInput?.['gsd']?.['max']) : filter_device
                                                    } else if (payloadCatalouge === 'Communication') {
                                                        filter_device = filteringInput?.['Data Rate'] !== '' ? filter_device?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Data Rate'] >= +filteringInput?.['Data Rate']) : filter_device
                                                        filter_device = filteringInput?.['Number of Beams'] !== '' ? filter_device?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Number of Beams'] >= +filteringInput?.['Number of Beams']) : filter_device
                                                    }
                                                    filter_device = filteringInput?.['size']?.['min'] !== '' ? filter_device.filter(device => +device?.["product-cookie"]?.['Size'] >= +filteringInput?.['size']?.['min']) : filter_device
                                                    filter_device = (filteringInput?.['size']?.['max'] !== '' && +filteringInput?.['size']?.['max'] >= +filteringInput?.['size']?.['min']) ? filter_device.filter(device => +device?.["product-cookie"]?.['Size'] <= +filteringInput?.['size']?.['max']) : filter_device
                                                    dispatch(setFilterPayloadDeviceList(filter_device))
                                                }
                                            }}
                                        ></Form.Control>
                                    </div>
                                </div>
                            </div>


                            <div className="d-flex flex-column w-100">
                                <Form.Label className="color-text mb-2">Size (U)</Form.Label>
                                <div className="d-flex align-items-center justify-content-between gap-1 w-100">
                                    <div className="w-50 d-flex flex-column gap-2">
                                        <span className="fs-13 color-text">Minimum </span>
                                        <Form.Control
                                            value={filteringInput?.size?.min}
                                            type="text"
                                            placeholder="Minimum size"
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onChange={(event) => {
                                                event.preventDefault();
                                                if (!isNaN(event.target.value) && event.target.value >= 0) {

                                                    setFilteringInput({
                                                        ...filteringInput,
                                                        size: {
                                                            min: event.target.value,
                                                            max: +event.target.value <= +filteringInput?.size?.max ? filteringInput?.size?.max : ''
                                                        },
                                                    })
                                                    let filter_device: any = []
                                                    filter_device = previousFilterdList?.filter(device => +device?.["product-cookie"]?.['Size'] >= +event.target.value)
                                                    filter_device = (filteringInput?.['size']?.['max'] !== '' && +filteringInput?.['size']?.['max'] >= +event.target.value) ? filter_device.filter(device => +device?.["product-cookie"]?.['Size'] <= +filteringInput?.['size']?.['max']) : filter_device
                                                    if (payloadCatalouge === 'Earth Observation') {
                                                        filter_device = (filteringInput?.['swath']?.['min'] !== '' && +filteringInput?.['swath']?.['min'] <= +filteringInput?.['swath']?.['max']) ? filter_device.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Swath'] >= +filteringInput?.['swath']?.['min']) : filter_device
                                                        filter_device = (filteringInput?.['swath']?.['max'] !== '' && +filteringInput?.['swath']?.['max'] >= +filteringInput?.['swath']?.['min']) ? filter_device.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Swath'] <= +filteringInput?.['swath']?.['max']) : filter_device
                                                        filter_device = (filteringInput?.['gsd']?.['min'] !== '' && +filteringInput?.['gsd']?.['min'] <= +filteringInput?.['gsd']?.['max']) ? filter_device?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['GSD'] >= +filteringInput?.['gsd']?.['min']) : filter_device
                                                        filter_device = (filteringInput?.['gsd']?.['max'] !== '' && +filteringInput?.['gsd']?.['max'] >= +filteringInput?.['gsd']?.['min']) ? filter_device?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['GSD'] <= +filteringInput?.['gsd']?.['max']) : filter_device
                                                    } else if (payloadCatalouge === 'Communication') {
                                                        filter_device = filteringInput?.['Data Rate'] !== '' ? filter_device?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Data Rate'] >= +filteringInput?.['Data Rate']) : filter_device
                                                        filter_device = filteringInput?.['Number of Beams'] !== '' ? filter_device?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Number of Beams'] >= +filteringInput?.['Number of Beams']) : filter_device
                                                    }
                                                    filter_device = (filteringInput?.['weight']?.['min'] !== '' && +filteringInput?.['weight']?.['min'] <= +filteringInput?.['weight']?.['max']) ? filter_device.filter(device => (+device?.["weight_in_gms"] / 1000) >= +filteringInput?.['weight']?.['min']) : filter_device
                                                    filter_device = (filteringInput?.['weight']?.['max'] !== '' && +filteringInput?.['weight']?.['max'] >= +filteringInput?.['weight']?.['min']) ? filter_device.filter(device => (+device?.["weight_in_gms"] / 1000) <= +filteringInput?.['weight']?.['max']) : filter_device
                                                    dispatch(setFilterPayloadDeviceList(filter_device))
                                                }
                                            }}
                                        ></Form.Control>
                                    </div>
                                    <i className="fe fe-minus mt-5 color_history"></i>
                                    <div className="w-50 d-flex flex-column gap-2">
                                        <span className="fs-13 color-text">Maximum </span>
                                        <Form.Control
                                            value={filteringInput?.size?.max}
                                            type="text"
                                            placeholder="Maximum size"
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onChange={(event) => {
                                                event.preventDefault();
                                                if (!isNaN(event.target.value) && event.target.value >= 0) {
                                                    setFilteringInput({
                                                        ...filteringInput,
                                                        size: {
                                                            ...filteringInput?.size,
                                                            max: event.target.value
                                                        },
                                                    })
                                                    let filter_device: any = []
                                                    filter_device = event.target.value !== '' && +event.target.value >= +filteringInput?.['size']?.['min'] ? previousFilterdList?.filter(device => +device?.["product-cookie"]?.['Size'] <= +event.target.value) : previousFilterdList?.filter(device => +device?.["product-cookie"]?.['Size'] >= +filteringInput?.['size']?.['min'])
                                                    filter_device = (filteringInput?.['size']?.['min'] !== '' && +filteringInput?.['size']?.['min'] <= +event.target.value) ? filter_device.filter(device => +device?.["product-cookie"]?.['Size'] >= +filteringInput?.['size']?.['min']) : filter_device
                                                    if (payloadCatalouge === 'Earth Observation') {
                                                        filter_device = (filteringInput?.['swath']?.['min'] !== '' && +filteringInput?.['swath']?.['min'] <= +filteringInput?.['swath']?.['max']) ? filter_device.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Swath'] >= +filteringInput?.['swath']?.['min']) : filter_device
                                                        filter_device = (filteringInput?.['swath']?.['max'] !== '' && +filteringInput?.['swath']?.['max'] >= +filteringInput?.['swath']?.['min']) ? filter_device.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Swath'] <= +filteringInput?.['swath']?.['max']) : filter_device
                                                        filter_device = (filteringInput?.['gsd']?.['min'] !== '' && +filteringInput?.['gsd']?.['min'] <= +filteringInput?.['gsd']?.['max']) ? filter_device?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['GSD'] >= +filteringInput?.['gsd']?.['min']) : filter_device
                                                        filter_device = (filteringInput?.['gsd']?.['max'] !== '' && +filteringInput?.['gsd']?.['max'] >= +filteringInput?.['gsd']?.['min']) ? filter_device?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['GSD'] <= +filteringInput?.['gsd']?.['max']) : filter_device
                                                    } else if (payloadCatalouge === 'Communication') {
                                                        filter_device = filteringInput?.['Data Rate'] !== '' ? filter_device?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Data Rate'] >= +filteringInput?.['Data Rate']) : filter_device
                                                        filter_device = filteringInput?.['Number of Beams'] !== '' ? filter_device?.filter(device => +device?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Number of Beams'] >= +filteringInput?.['Number of Beams']) : filter_device
                                                    }
                                                    filter_device = (filteringInput?.['weight']?.['min'] !== '' && +filteringInput?.['weight']?.['min'] <= +filteringInput?.['weight']?.['max']) ? filter_device.filter(device => (+device?.["weight_in_gms"] / 1000) >= +filteringInput?.['weight']?.['min']) : filter_device
                                                    filter_device = (filteringInput?.['weight']?.['max'] !== '' && +filteringInput?.['weight']?.['max'] >= +filteringInput?.['weight']?.['min']) ? filter_device.filter(device => (+device?.["weight_in_gms"] / 1000) <= +filteringInput?.['weight']?.['max']) : filter_device
                                                    dispatch(setFilterPayloadDeviceList(filter_device))
                                                }
                                            }}
                                        ></Form.Control>
                                    </div>
                                </div>
                            </div>
                            <div className="p-3"></div>
                        </div>
                    </div>

                    <div className='d-flex w-82 py-4 h-100 position-relative'>
                        <div className={`d-flex flex-column ${openInputSlider ? 'opacity-75' : 'opacity-100'} h-100 position-relative transition w-100 overflow-y-auto`}>
                            <div className={`d-flex flex-wrap w-100 ps-5`}>
                                {filterPayloadDeviceList?.length > 0 &&
                                    filterPayloadDeviceList.map((data: any, i) => {
                                        let gpio_interface = data?.["component_specific_attributes"]?.["interfaces"].filter(item => item === 'GPIO');
                                        let filter_interfaces = data?.["component_specific_attributes"]?.["interfaces"].filter(item => item !== 'GPIO');
                                        let all_interfaces = [...filter_interfaces, ...gpio_interface];

                                        let weight = data?.["weight_in_gms"];
                                        return (
                                            <div
                                                className={
                                                    data?.["uniqueId"] === selectedDeviceId
                                                        ? "common-card-box active done"
                                                        : "common-card-box"
                                                }
                                                key={i}
                                                onClick={() => {
                                                    if (selectedDeviceId !== data?.["uniqueId"]) {
                                                        setSelectedDeviceId(data?.["uniqueId"]);
                                                        setUserInput({
                                                            ...userInput,
                                                            'atmos-data': data,
                                                            time_per_orbit: '5',
                                                            AltMeasureAccuracy: '1',
                                                            downlinkDatasize: '1',
                                                            payloadName: data?.['name'].includes('/') ? `${data?.['name'].split('/')[0] - [1]}` : data?.['name'],
                                                            interfaces: data?.["component_specific_attributes"]?.["interfaces"],
                                                            gpioCount: data?.['component_specific_attributes']?.['interfaces'].includes('GPIO') ? 1 : '',
                                                            payloadImageSrc: pyloadSrc,
                                                        })
                                                        onSelectDevices({
                                                            ...userInput,
                                                            'atmos-data': data,
                                                            payloadName: data?.['name'].includes('/') ? `${data?.['name'].split('/')[0] - [1]}` : data?.['name'],
                                                            interfaces: data?.["component_specific_attributes"]?.["interfaces"],
                                                            gpioCount: data?.['component_specific_attributes']?.['interfaces'].includes('GPIO') ? 1 : '',
                                                            payloadImageSrc: pyloadSrc,
                                                        });
                                                    } else {
                                                        setUserInput({
                                                            ...userInput,
                                                            time_per_orbit: '5',
                                                            AltMeasureAccuracy: '1',
                                                            downlinkDatasize: '1',
                                                            payloadName: data?.['name'].includes('/') ? `${data?.['name'].split('/')[0] - [1]}` : data?.['name'],
                                                        })
                                                    }
                                                    setOpenInputSlider(true)
                                                }}
                                            >
                                                <div className="common-card-header d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <h4 className="mb-2">{data?.["name"]}</h4>
                                                    </div>
                                                    <div className="btn-list more-button" onClick={(e) => e.stopPropagation()}>
                                                        <Dropdown drop={"end"} show={showBtnGroup === data?.["uniqueId"]} >
                                                            <Dropdown.Toggle variant="transparent text-white fs-20"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    if (showBtnGroup === data?.["uniqueId"]) {
                                                                        setShowBtnGroup("");
                                                                    } else {
                                                                        setShowBtnGroup(data?.["uniqueId"]);
                                                                    }
                                                                }}
                                                            >
                                                                <i className="fe fe-more-vertical"></i>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item
                                                                    eventKey="0"
                                                                    onClick={() => {
                                                                        setSelectedDeviceId(data?.["uniqueId"]);
                                                                        onSelectDevices({
                                                                            ...userInput,
                                                                            'atmos-data': data,
                                                                            payloadName: data?.['name'].includes('/') ? `${data?.['name'].split('/')[0] - [1]}` : data?.['name'],
                                                                            interfaces: data?.["component_specific_attributes"]?.["interfaces"],
                                                                            gpioCount: data?.['component_specific_attributes']?.['interfaces'].includes('GPIO') ? 1 : '',
                                                                            payloadImageSrc: pyloadSrc,
                                                                        });
                                                                        setOpenInputSlider(false);
                                                                        dispatch(setPayloadSliderTrue());
                                                                        setShowBtnGroup("");
                                                                    }}
                                                                >
                                                                    Summary
                                                                </Dropdown.Item>
                                                                {data?.['owner-org'] !== 'System' && <Dropdown.Item
                                                                    eventKey="1"
                                                                    onClick={() => {
                                                                        setDeleteCatalougeId(data?.['uniqueId'])
                                                                        setOpenDeleteModal(true)
                                                                        setShowBtnGroup("");
                                                                    }}
                                                                >
                                                                    Delete
                                                                </Dropdown.Item>}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <div className="common-card-content">
                                                    <div className="d-flex flex-wrap">
                                                        <div className="stats-alpha">
                                                            <strong>
                                                                {data?.["product-cookie"]?.["Size"] + " U"}
                                                            </strong>
                                                            <span>Size</span>
                                                        </div>
                                                        <div className="stats-alpha">
                                                            <strong>
                                                                {weight > 999
                                                                    ? weight / 1000 + " kg"
                                                                    : weight + " gm"}
                                                            </strong>
                                                            <span>Mass</span>
                                                        </div>
                                                        <div className="stats-alpha">
                                                            <strong>
                                                                {data?.["max_power_consumption_in_watts"] + " W"}
                                                            </strong>
                                                            <span>Peak Power</span>
                                                        </div>
                                                        {data?.["component_specific_attributes"]?.["typeSpecificParams"] &&
                                                            Object.keys(data?.["component_specific_attributes"]?.["typeSpecificParams"])?.length > 0 &&
                                                            Object.keys(data?.["component_specific_attributes"]?.["typeSpecificParams"]).map((k) => {
                                                                if (k !== 'Frequency Bands') {
                                                                    return (
                                                                        <div key={k} className="stats-alpha">
                                                                            <strong>
                                                                                {k === "GSD"
                                                                                    ? data?.["component_specific_attributes"]?.[
                                                                                    "typeSpecificParams"
                                                                                    ]?.[k] + " m"
                                                                                    : k === "Swath"
                                                                                        ? data?.["component_specific_attributes"]?.[
                                                                                        "typeSpecificParams"
                                                                                        ]?.[k] + " km"
                                                                                        : data?.["component_specific_attributes"]?.[
                                                                                        "typeSpecificParams"
                                                                                        ]?.[k]}
                                                                            </strong>
                                                                            <div className="d-flex align-items-center">
                                                                                <span>{k}</span>
                                                                                {(k === 'GSD' || k === 'Swath') && <OverlayTrigger
                                                                                    placement="right"
                                                                                    overlay={
                                                                                        <Popover id="popover-basic">
                                                                                            <Popover.Body>
                                                                                                <span className='color-text text-center d-flex'>{k === 'GSD' ? '@GSD 500km' : '@Swath 500km'}</span>
                                                                                            </Popover.Body>
                                                                                        </Popover>
                                                                                    }
                                                                                >
                                                                                    <sup>
                                                                                        <i className="fe fe-info ms-1 fs-12 text-info"></i>
                                                                                    </sup>
                                                                                </OverlayTrigger>}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            })}
                                                        {data?.["component_specific_attributes"]?.["additionalParams"] &&
                                                            Object.keys(data?.["component_specific_attributes"]?.["additionalParams"])?.length > 0 &&
                                                            Object.keys(data?.["component_specific_attributes"]?.["additionalParams"]).map((k) => {
                                                                return (
                                                                    <div key={k} className="stats-alpha">
                                                                        <strong>
                                                                            {data?.["component_specific_attributes"]?.[
                                                                                "additionalParams"
                                                                            ]?.[k]}
                                                                        </strong>
                                                                        <div className="d-flex align-items-center">
                                                                            <span>{k}</span>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}

                                                        {data?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Frequency Bands'] && data?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Frequency Bands'].length > 0 &&
                                                            <div className="d-flex flex-column mt-3">
                                                                <div className="d-flex gap-1 w-100 flex-wrap">
                                                                    {data?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Frequency Bands'].map((val, i) => {
                                                                        return (
                                                                            <div key={i} className="d-flex gap-1">
                                                                                <strong>{val}</strong>
                                                                                <strong>{`${data?.["component_specific_attributes"]?.["typeSpecificParams"]?.['Frequency Bands'].length !== i + 1 ? '|' : ''}`}</strong>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                                <span>Frequency Bands</span>
                                                            </div>}

                                                    </div>
                                                </div>
                                                <div className="common-card-footer mb-2">
                                                    <span className="footer-title">Interfaces</span>
                                                    <div className="d-flex w-100 flex-wrap">
                                                        {data?.["component_specific_attributes"]?.["interfaces"]?.length > 0 &&
                                                            all_interfaces.map((val, i) => {
                                                                return (
                                                                    <div className="d-flex" key={i}>
                                                                        {val !== 'GPIO' &&
                                                                            <span
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    if (selectedDeviceId === data?.["uniqueId"]) {
                                                                                        let interfaces: any = []
                                                                                        if (userInput?.['interfaces'].length > 1) {
                                                                                            if (userInput?.['interfaces'].includes(val)) {
                                                                                                interfaces = userInput?.['interfaces'].filter((item) => item !== val);
                                                                                            } else {
                                                                                                interfaces = [...userInput?.['interfaces'], val];
                                                                                            }
                                                                                        } else {
                                                                                            if (userInput?.['interfaces'][0] !== val) {
                                                                                                interfaces = [...userInput?.['interfaces'], val];
                                                                                            } else {
                                                                                                interfaces = userInput?.['interfaces'];
                                                                                            }
                                                                                        }
                                                                                        setUserInput({
                                                                                            ...userInput,
                                                                                            interfaces: interfaces,
                                                                                            gpioCount: userInput?.['interfaces'].includes('GPIO') ? userInput?.['gpioCount'] : '',
                                                                                        })
                                                                                        onSelectDevices({
                                                                                            ...userInput,
                                                                                            'atmos-data': data,
                                                                                            interfaces: interfaces,
                                                                                            gpioCount: userInput?.['interfaces'].includes('GPIO') ? userInput?.['gpioCount'] : ''
                                                                                        });
                                                                                    }
                                                                                }}
                                                                                className={
                                                                                    selectedDeviceId === data?.["uniqueId"]
                                                                                        ? data?.["component_specific_attributes"]?.[
                                                                                            "interfaces"
                                                                                        ].length > 1
                                                                                            ? (userInput?.['interfaces'] && userInput?.['interfaces'].includes(val))
                                                                                                ? "badge-pill active mb-2"
                                                                                                : "badge-pill mb-2"
                                                                                            : "badge-pill-active mb-2"
                                                                                        : "badge-pill-inactive mb-2"
                                                                                }
                                                                                key={i}
                                                                            >
                                                                                {val}
                                                                            </span>
                                                                        }
                                                                        {val === 'GPIO' &&
                                                                            <div className="d-flex me-2">
                                                                                <span className={
                                                                                    selectedDeviceId !== data?.["uniqueId"] ? 'badge-pill-inactive' : (userInput?.['interfaces'] && userInput?.['interfaces'].includes('GPIO')) ? "badge-pill active " : "badge-pill"
                                                                                } onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    if (selectedDeviceId === data?.["uniqueId"]) {
                                                                                        let interfaces: any = userInput?.['interfaces']
                                                                                        let gpioCount: any = userInput?.['interfaces'].includes('GPIO') ? userInput?.['gpioCount'] : ''

                                                                                        if (interfaces.includes('GPIO')) {
                                                                                            if (interfaces.length > 1) {
                                                                                                interfaces = interfaces.filter((item) => item !== 'GPIO');
                                                                                                gpioCount = ''
                                                                                            }
                                                                                        } else {
                                                                                            interfaces = [...interfaces, 'GPIO'];
                                                                                            gpioCount = 1
                                                                                        }
                                                                                        setUserInput({
                                                                                            ...userInput,
                                                                                            interfaces: interfaces,
                                                                                            gpioCount: gpioCount,
                                                                                        })
                                                                                        onSelectDevices({
                                                                                            ...userInput,
                                                                                            'atmos-data': data,
                                                                                            interfaces: interfaces,
                                                                                            gpioCount: gpioCount
                                                                                        });

                                                                                    }
                                                                                }} >GPIO
                                                                                </span>
                                                                                {(selectedDeviceId === data["uniqueId"] && (userInput?.['interfaces'] && userInput?.['interfaces'].includes('GPIO'))) && <div className="d-flex gap-2 align-items-center justify-content-center align-self-end mb-3" >
                                                                                    <button disabled={userInput?.['gpioCount'] === 1} className={"gpio-incriment bg-transparent"} onClick={(e) => {
                                                                                        e.stopPropagation()
                                                                                        if (userInput?.['gpioCount'] > 1) {
                                                                                            let gpioCount: any = ''
                                                                                            let interfaces: any = userInput?.['interfaces']
                                                                                            gpioCount = userInput?.['gpioCount'] > 1 ? userInput?.['gpioCount'] - 1 : 1
                                                                                            setUserInput({
                                                                                                ...userInput,
                                                                                                interfaces: interfaces,
                                                                                                gpioCount: gpioCount,
                                                                                            })
                                                                                            onSelectDevices({
                                                                                                ...userInput,
                                                                                                'atmos-data': data,
                                                                                                interfaces: interfaces,
                                                                                                gpioCount: gpioCount
                                                                                            });
                                                                                        }

                                                                                    }}><i className="fe fe-minus" /></button>
                                                                                    <span>{userInput?.['gpioCount']}</span>
                                                                                    <button className="gpio-incriment bg-tr bg-transparent" disabled={+userInput?.['gpioCount'] === 5} onClick={(e) => {
                                                                                        e.stopPropagation()
                                                                                        let gpioCount: any = ''
                                                                                        let interfaces: any = userInput?.['interfaces']
                                                                                        if (+userInput?.['gpioCount'] <= 5) {
                                                                                            gpioCount = userInput?.['gpioCount'] + 1
                                                                                            setUserInput({
                                                                                                ...userInput,
                                                                                                interfaces: interfaces,
                                                                                                gpioCount: gpioCount,
                                                                                            })
                                                                                            onSelectDevices({
                                                                                                ...userInput,
                                                                                                'atmos-data': data,
                                                                                                interfaces: interfaces,
                                                                                                gpioCount: gpioCount
                                                                                            });
                                                                                        }
                                                                                    }}><i className="fe fe-plus" /></button>
                                                                                </div>}
                                                                            </div>}
                                                                    </div>
                                                                );
                                                            })}

                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                            <div className={`d-flex flex-column gap-2 w-95 ps-5  ${filterPayloadDeviceList.length <= 2 && 'position-absolute bottom-0'} pb-5 align-items-center justify-content-center`}>
                                <div
                                    className={`add-mission-row w-100`}
                                    onClick={() => {
                                        if (isPayloadSlider) {
                                            dispatch(setPayloadSliderFalse());
                                        }
                                        dispatch(setIsNewPayloadCatelog(true))
                                    }}
                                >
                                    <i className="fe fe-plus fs-20  me-3"></i>
                                    Add Payload Device
                                </div>
                            </div>
                        </div>
                        <InputSlider toggle={() => { setOpenInputSlider(!openInputSlider) }} openInputSlider={openInputSlider}
                            onSelectDevices={onSelectDevices} userInput={userInput} setUserInput={setUserInput} />
                    </div>
                </div>}
            <Dialog
                onClick={(event) => {
                    if (event) {
                        deleteUserCatalouge(deleteCatalougeId)
                    }
                    setOpenDeleteModal(false)
                    setDeleteCatalougeId('')
                }}
                openModel={openDeleteModel} comment='Are you sure you want to delete this payload device ?'
            />

            {isNewPayloadCatelog && <NewPayloadCatelog payloadsList={PayloadsList}
                onSelectPayload={onSelectPayload} payloadType={payloadCatalouge} onCheckSteps={onCheckSteps}
                payloadSubtype={payloadType} setPayloadSubType={setPayloadType} imgPayloadDetails={payloadSubType}
                setImgPayloadDetails={setPayloadSubType} configImg={configImg} setConfigImg={setConfigImg} configComm={configComm}
                radarPayloadDetails={payloadSubType} setRadarPayloadDetails={setPayloadSubType} handleFreqBands={(band) => {
                    if (selectedBands.includes(band)) {
                        setSelectedBands(selectedBands.filter(band => band !== band))
                    } else {
                        setSelectedBands([...selectedBands, band])
                    }
                }}
                setConfigComm={setConfigComm} selectedBands={selectedBands} getAllCatalogs={getAllCatalogs}
            />}

        </div>
    )
}

export default SecondStep