import { panelPublicUrl } from "../../../../../../panelurl";
import { ADCSSvg } from "../scenario_visualization/right_header_section/svgs/Svgs";
import ModalWrapper from "./ModalWrapper";

interface ModalProviderProps {
    modalRef: React.MutableRefObject<boolean>
    pinned: boolean;
    open: boolean;
    current_modal: boolean | string;
    expanded: boolean | string;
    set_expanded: (modal: string) => void;
    close_modal: (modal: string) => void;
    pinned_modal: (modal: string) => void;
    set_top_modal: (modal: string) => void;
    adcsAgilityGraphUrl: any[]
}

const ADCSGraphModal: React.FC<ModalProviderProps> = ({ open, close_modal, pinned, pinned_modal,
    current_modal, set_top_modal, expanded, set_expanded, adcsAgilityGraphUrl, modalRef }) => {
    return (
        <ModalWrapper
            pinned={pinned}
            open={open}
            close_modal={close_modal}
            icon={<ADCSSvg />}
            label='ADCS'
            pinned_modal={pinned_modal}
            modal='adcs'
            expanded={expanded}
            set_expanded={set_expanded}
            current_modal={current_modal}
            set_top_modal={set_top_modal}
            modalRef={modalRef}
        >
            <div className="scenario__modal__container__body overflow-auto">
                {adcsAgilityGraphUrl?.map((item, index) => (
                    <div className='graph_container' key={index}>
                        <label className='color-atmos'>{item?.['name']}</label>
                        <iframe src={`${panelPublicUrl}${item?.['url']}`} loading="lazy" />
                    </div>
                ))}
            </div>
        </ModalWrapper>
    )
}

export default ADCSGraphModal