// @ts-nocheck
import React from 'react';
import InputRange, { InputRangeProps } from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import './TimelineSlider.css'

interface TimelineSliderProps {
    min: number;
    max: number;
    value: {
        min: number;
        max: number;
    }
    onChange: (value: any) => void;
}

const TimelineSlider: React.FC<TimelineSliderProps> = ({ min, max, value, onChange }) => {

    return (
        <div className='w-100 cursor-auto' {...InputRangeProps}>
            <InputRange
                maxValue={max}
                minValue={min}
                value={value}
                onChange={onChange}
            />
        </div>
    );
}

export default TimelineSlider