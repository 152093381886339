// @ts-nocheck
import './ScenarioModal.css'
import { ExpandSvg, PinSvg } from "../scenario_visualization/right_header_section/svgs/Svgs";
import CustomToolTip from "../../../../../CommonComponents/CustomToolTip";
import DraggableBox from './DraggableBox';
import { Fragment } from 'react';

interface ModalProviderProps {
    modalRef: React.MutableRefObject<boolean>
    pinned: boolean;
    open: boolean;
    close_modal: (modal: string) => void;
    set_top_modal: (modal: string) => void;
    pinned_modal: (modal: string) => void;
    set_expanded: (modal: string) => void;
    children: React.ReactNode;
    icon: React.ReactNode;
    label: string;
    modal: string;
    current_modal: boolean | string;
    expanded: boolean | string;
}


const ModalWrapper: React.FC<ModalProviderProps> = ({ open, close_modal, icon, children, label, pinned,
    pinned_modal, modal, current_modal, set_top_modal, expanded, set_expanded, modalRef }) => {

    return (
        <Fragment>
            {open &&
                <DraggableBox
                    modal={modal}
                    modalRef={modalRef}
                    expanded={expanded}
                    className={current_modal === modal ? 'z__Index__999' : 'z__Index__99'}
                >
                    <div className={`scenario__modal`} onClick={(e) => e.stopPropagation()}>
                        <div className='scenario__modal__container'>
                            <div className='scenario__modal__container__header'
                                onClick={(e) => {
                                    e.stopPropagation()
                                    set_top_modal(modal)
                                }}
                            >
                                <div className='d-flex gap-2 align-items-center'>
                                    {icon}
                                    <span className="text-white">{label}</span>
                                </div>
                                <div className='d-flex align-items-center gap-3'>
                                    {modal !== 'schedule' &&
                                        <CustomToolTip title={expanded ? 'Collapse' : 'Expand'} placement="top">
                                            <button className='bg-transparent p-0 d-flex align-items-center'
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    set_expanded(modal)
                                                }}>
                                                <ExpandSvg expanded={expanded === modal} />
                                            </button>
                                        </CustomToolTip>
                                    }

                                    <CustomToolTip title={pinned ? 'Unpin' : 'Pin'} placement="top">
                                        <button className={`bg-transparent p-0 d-flex align-items-center`}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                pinned_modal(modal)
                                            }}>
                                            <PinSvg color={pinned ? '#CCF54E' : '#F0F0F0'} />
                                        </button>
                                    </CustomToolTip>
                                    <CustomToolTip title='Close' placement="top">
                                        <button className='bg-transparent p-0 d-flex align-items-center'
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                close_modal(modal)
                                            }}>
                                            <i className='fe fe-x fs-18'></i>
                                        </button>
                                    </CustomToolTip>
                                </div>
                            </div>
                            {children}
                        </div>
                    </div>
                </DraggableBox>
            }
        </Fragment>
    )
}

export default ModalWrapper