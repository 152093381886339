// @ts-nocheck
import { Form } from "react-bootstrap";
import { containsSpecialChars } from "../../../../../CommonComponents/CommonFunctions";
import { toast } from "react-toastify";

const InputSlider = ({ toggle, openInputSlider, onSelectDevices, userInput, setUserInput }) => {


  return (
    <div className={`${openInputSlider ? 'w-100' : 'd-none'} h-100 position-absolute top-0 right-0 align-items-start justify-content-end d-flex`} onClick={toggle}>
      <div className={`transition bg-right-slider h-100 ${openInputSlider ? 'w-25' : 'd-none'} rounded-2`} onClick={(e) => { e.stopPropagation() }}>
        <div className='w-100 d-flex flex-column gap-3'>
          <div className="w-100 d-flex align-items-center justify-content-between ">
            <span className="mb-3 fs-23 w-100 slider-header color-text color-atmos-secondary ps-5">Configure</span>
            <div className='d-flex p-2 rounded cursor-pointer align-items-center justify-content-center bg-blur me-3 hover' onClick={toggle}>
              <i className="fe fe-x fs-23 color-atmos"></i>
            </div>
          </div>
          <Form validated={(userInput?.['time_per_orbit'] === '' || userInput?.['time_per_orbit'] === '0') || userInput?.['downlinkDatasize'] === '' || userInput?.['AltMeasureAccuracy'] === ''} className="d-flex flex-column gap-3 w-95 ps-5">
            <div className="d-flex flex-column ">
              <Form.Label className="color-text mb-3">Payload Name </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter payload name"
                value={userInput?.['payloadName']}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                  }
                }}
                onChange={(event) => {
                  event.preventDefault();
                  if (event.target.value.length <= 30 && !containsSpecialChars(event.target.value)) {
                    setUserInput({
                      ...userInput,
                      payloadName: event.target.value.trimStart()
                    })
                    onSelectDevices({
                      ...userInput,
                      payloadName: event.target.value.trimStart()
                    })
                  } else {
                    toast.error(event.target.value.length > 30 ? 'Name must be less than 30 characters.' : 'Name cannot contain special characters.', { toastId: 'o' })
                  }
                }}
              ></Form.Control>
            </div>
            <div className="d-flex flex-column ">
              <Form.Label className="color-text mb-3">Average Active Duration per Orbit (min)</Form.Label>
              <Form.Control
                required
                step={1}
                min={1}
                type="number"
                placeholder="Enter average active duration per orbit (min)"
                value={userInput?.['time_per_orbit']}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                  }
                }}
                onChange={(event) => {
                  event.preventDefault();
                  if (event.target.value >= 0 && event.target.value.length <= 5) {
                    setUserInput({
                      ...userInput,
                      time_per_orbit: event.target.value !== '' ? parseInt(event.target.value) : ''
                    })
                    onSelectDevices({
                      ...userInput,
                      time_per_orbit: event.target.value !== '' ? parseInt(event.target.value) : ''
                    })
                  }
                }}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                {userInput?.['time_per_orbit'] === '0' ? 'Average active duration per orbit can not be 0' : ' Please enter average active duration per orbit (min).'}
              </Form.Control.Feedback>
            </div>

            <div className="d-flex flex-column ">
              <Form.Label className="color-text mb-3">Average Data Generated per second (MB/s)</Form.Label>
              <Form.Control
                as="input"
                required
                step={'any'}
                type="number"
                placeholder="Enter average data generated per second (MB/s)"
                value={userInput?.['downlinkDatasize']}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                  }
                }}
                onChange={(event) => {
                  event.preventDefault();
                  if (Number(event.target.value) >= 0 && event.target.value.length <= 6) {
                    setUserInput({
                      ...userInput,
                      downlinkDatasize: event.target.value
                    })
                    onSelectDevices({
                      ...userInput,
                      downlinkDatasize: event.target.value
                    })
                  }
                }}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                Please enter average data generated per second (MB/s).
              </Form.Control.Feedback>
            </div>

            <div className="d-flex flex-column ">
              <Form.Label className="color-text mb-3">Required Pointing Accuracy (deg)</Form.Label>
              <Form.Control
                required
                step={'any'}
                min={0}
                max={1}
                type="number"
                placeholder="Enter required pointing accuracy (deg)"
                value={userInput?.['AltMeasureAccuracy']}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                  }
                }}
                onChange={(event) => {
                  event.preventDefault();
                  if (Number(event.target.value) >= 0 && Number(event.target.value) <= 1) {
                    setUserInput({
                      ...userInput,
                      AltMeasureAccuracy: event.target.value
                    })
                    onSelectDevices({
                      ...userInput,
                      AltMeasureAccuracy: event.target.value
                    })
                  } else {
                    toast.error('Pointing accuracy should be between 0 and 1.', { toastId: 'o' })
                  }
                }}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                Please enter required pointing accuracy (deg).
              </Form.Control.Feedback>
            </div>
          </Form>
        </div>
      </div >
    </div>
  )
}

export default InputSlider