import React from "react";
import CustomToolTip from "../../../../../CommonComponents/CustomToolTip";

interface ModalHeaderProps {
  minimize_modal: boolean;
  set_minimize_modal: () => void;
  close: () => void;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ set_minimize_modal, close, minimize_modal }) => {

  const header = {
    title: "Scenario Creation",
    img: require("../Images/ScenarioCreationIcon.svg").default,
  };

  return (
    <div className="modal__Header" onClick={(e: any) => e.stopPropagation()}>
      <div className="modalHeaderImg">
        <img
          src={header.img}
          className="active-img"
        />
      </div>
      <span>{header.title}</span>
      <div className="d-flex flex-1 justify-content-end">
        <CustomToolTip title={!minimize_modal ? 'Maximize' : "Minimize"} placement="top">
          <button
            className="modalCloseBtn modalMinimizeBtn"
            onClick={() => {
              set_minimize_modal()
            }}
          >
            {!minimize_modal &&
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" viewBox="0 0 10 14" fill="none">
                <path d="M9.19981 13L4.9998 8.8L0.799805 13M0.799805 1L4.9998 5.2L9.19981 1" stroke="#F0F0F0" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            }
            {minimize_modal &&
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                <path d="M1.00625 11.2214L5.20625 15.4214L9.40625 11.2214M9.41113 5.2002L5.20048 1.01088L1.01116 5.22154" stroke="#F0F0F0" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            }
          </button>
        </CustomToolTip>
        <CustomToolTip title="Close" placement="top">
          <button
            className="modalCloseBtn"
            onClick={() => {
              close()
            }}
          >
            <img src={require("../Images/CloseButtonWhite.svg").default} />
          </button>
        </CustomToolTip>
      </div>
    </div>
  );
};

export default ModalHeader;
